import React,{useRef,useEffect} from 'react'
import DetailLogo from '../detail_logo';
import Slider from 'react-slick'
import GetBodyItem from '../../../components/_helpers/_get_body_item'
import { Picture } from 'react-responsive-picture';
import SliderArrow from '../../../components/sliders/slider_arrow';
import { SliderSlickButton } from '../../../components/buttons/_button_slider_slick';

function ModelConocelo(_props){
    let modelo = _props._model;
    const swiperConocelo = useRef(null)
    let getBodyItem = new GetBodyItem();
    let data = getBodyItem.get_item(modelo,"PrismicModelBodyConoceloGrupo")
    let items = null;
    let sliderArrow = new SliderArrow();
    const bannerSectionRef = useRef(null)

    if(data) {
        items = data.items;
    }

    function get_item(_item,_key){
        return(
            <div key={`model_conocelo_item_${_key}`} className="detail_conocelo_item model_detail_section">
                <div className="detail_conocelo_item_imagecontainer">
                    <Picture
                        sources = {[
                        {
                            srcSet: _item.conocelo_image.lg.url+"&q=95",
                            media: "(max-width: 768px)",
                        },
                        {
                            srcSet: _item.conocelo_image.url+"&q=95",
                            media: "(max-width: 1365px)",
                        },
                        {
                            srcSet: _item.conocelo_image.url+"&q=95",
                            media: "(min-width: 1366px)",
                        }
                        ]}
                    />
                </div>
                <div className="detail_conocelo_item_info">
                    <div className="detail_conocelo_item_number_container">
                        <div className="detail_conocelo_item_number_bg">
                            <div className="detail_conocelo_item_number_bg_white"></div>
                            <div className="detail_conocelo_item_number_text">{_key+1}</div>
                        </div>
                    </div>
                    <div className="detail_conocelo_item_title">{_item.conocelo_title.text}</div>
                    <div className="detail_conocelo_item_description">{_item.conocelo_description.text}</div>
                </div>

            </div>
        )
    }
    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SliderSlickButton _className="slick-button-prev"/>,
        nextArrow: <SliderSlickButton _className="slick-button-next" isNext={true}/>
    };

    useEffect(()=>{
        if(!bannerSectionRef.current) return;
        sliderArrow.init(bannerSectionRef);
    })

    return ( data ?
        <div className="detail_conocelo model_detail_section" ref={bannerSectionRef}>
            <div className="detail_conocelo_stage">
                <div className="detail_conocelo_header">
                    <DetailLogo _name={modelo.data.model_name.html} _title="Conócelo"></DetailLogo>
                </div>
                <div className="detail_conocelo_body">
                    <div className="detail_conocelo_body_slider">
                        <div className={`banner__prev cursor__change`} onClick={() => swiperConocelo.current.slickPrev()} ></div>
                        <div className={`banner__next cursor__change`} onClick={() => swiperConocelo.current.slickNext()} ></div>
                        <Slider ref={swiperConocelo}  {...settings}>
                            {
                                items.map((data, _key)=>{
                                    return (
                                        get_item(data,_key)
                                    )
                                })
                            }
                        </Slider>
                    </div>
                    <div className="detail_conocelo_body_info">
                        <div className="detail_conocelo_info_title">

                        </div>
                    </div>
                </div>
            </div>
        </div>: null
    )
}
export default ModelConocelo
