import React from 'react'

const Maintenance = props => (
  <svg viewBox="0 0 31.9 36" {...props}>
    <path
      d="M31 26.8l-4.6-4.6 1.4-1.4c.4-.4.7-1 .7-1.7s-.2-1.2-.7-1.7c-.5-.5-1.1-.7-1.7-.7v-.1c0-.6-.2-1.2-.7-1.7-.5-.5-1.1-.7-1.7-.7 0-.6-.2-1.3-.7-1.7-.5-.5-1.1-.7-1.7-.7 0-.6-.2-1.2-.7-1.7-.9-.9-2.4-.9-3.3 0l-1.4 1.4-3.2-3.2c.7-2.3.1-4.8-1.6-6.6C9.4.2 7-.4 4.7.2c-.2.1-.4.3-.5.6-.1.3 0 .6.2.8l2.9 2.9c.2.2.2.4 0 .6L5 7.3c-.2.2-.4.2-.6 0L1.6 4.4c-.2-.2-.5-.3-.8-.2-.3.1-.5.3-.6.5-.6 2.3 0 4.7 1.7 6.4 1.7 1.7 4.3 2.3 6.6 1.6l.7.7-.8.8c-.7.7-1.1 1.6-1.1 2.6v4.9l-1.2 1.2-1.3-1.3L1 25.4 11.7 36l3.8-3.8-1.3-1.3 1.2-1.2 2.3-.1c.9 0 1.8-.4 2.4-1.1l2.1-2.1 4.6 4.6c.6.6 1.3.9 2.1.9s1.6-.3 2.1-.9.9-1.3.9-2.1-.3-1.6-.9-2.1zM8.4 11.7c-2 .7-4.3.2-5.8-1.3-1.3-1.3-1.9-3.2-1.5-5L3.7 8c.5.5 1.4.5 2 0L8 5.7c.5-.5.5-1.4 0-2L5.4 1.1c1.8-.4 3.7.2 5 1.5 1.5 1.5 2 3.8 1.3 5.8-.1.2 0 .4.1.5l3.5 3.5-2.8 2.8L9 11.7c-.2-.1-.4-.1-.6 0zm3.3 22.9l-9.2-9.2L4.9 23l9.2 9.2-2.4 2.4zm7.5-7c-.4.4-1 .7-1.5.7l-2.6.1c-.2 0-.3.1-.4.2L13.2 30 7 23.8l1.4-1.4c.1-.1.2-.3.2-.5v-5.1c0-.6.2-1.2.7-1.7l.8-.8 1.6 1.6-.2.2c-.3.3-.3.7 0 .9.1.1.3.2.5.2s.3-.1.5-.2l5.9-5.9c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5l-.9.9c-.3.3-.3.7 0 .9.3.3.7.3.9 0l.9-.9c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5l-1.1 1c-.3.3-.3.7 0 .9.3.3.7.3.9 0l.9-.9c.4-.4 1.1-.4 1.5 0 .2.2.3.5.3.7 0 .3-.1.5-.3.7l-.9.9c-.3.3-.3.7 0 .9.1.1.3.2.5.2s.3-.1.5-.2l.9-.9c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5l-7.8 7.8zm11.1 2.7c-.8.8-2.1.8-2.8 0l-4.6-4.6 2.8-2.8 4.6 4.6c.4.4.6.9.6 1.4s-.2 1-.6 1.4z"      
    />

    {props.section!==undefined ? 
      <>               
        <linearGradient id={`${props.section}_maintenance`}>
          <stop offset="2%" stopColor={props.fillstart} />
          <stop offset="100%" stopColor={props.fillend} />
        </linearGradient>
        <linearGradient id={`${props.section}_maintenance_animation`}>
          <stop offset="0%" stopColor={props.fillend} >            
          </stop>
          <stop offset="1%" stopColor={props.fillstart} >
            <animate attributeName="offset"   values=".01; .01; .01; .25; .5;  .75; .85;.98; .85;.75;.5;.25;.01;.01;0.1;" repeatCount="indefinite" dur="2s" />
          </stop>
          <stop offset="2%" stopColor={props.fillstart} >
              <animate attributeName="offset" values=".02; .25; .5;   .75; .99; .99; .99;.99;.99;.99;.99;.75;.5;0.25;0.2" repeatCount="indefinite" dur="2s" />
          </stop>
          <stop offset="100%" stopColor={props.fillend} >          
          </stop>
        </linearGradient>
      </>
      
      : null 
    }
  </svg>
)

export default Maintenance