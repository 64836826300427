import React from 'react'
import DetailLogo from '../detail_logo';

function ModelLeed(props){
    
    let _model = props._model;
    
    let body =  _model.data.body;

    let found = false;
    let item_count = 0;    
    let is_active = false;
    while (!found && item_count<body.length)
    {
        let item = body[item_count];
        if(item.__typename==="PrismicModelBodyLeed")
        {
            found = true;
            is_active=item.primary.leed_active==='activo';
        }
        item_count++;
    }    
    return ( found && is_active ?
        <div className="detail_leed model_detail_section">
            <div className="detail_leed_ribbon">
                <img src="/assets/images/model_detail/leed/calorex_detalle_modelo_producto_leed_badge.png" alt="leed ribbon"></img>
            </div>
            <div className="detail_leed_body_bg"></div>
            
            <div className="detail_leed_body">
                
                <div className="detail_leed_body_left detail_leed_item">
                    <div className="detail_leed_header">
                        <div className="detail_leed_header_logo">
                            <img src="/assets/images/model_detail/leed/calorex_detalle_modelo_producto_leed_logo.png" alt="leed icono"></img>
                        </div>
                        <div className="detail_leed_header_title">
                            <p>Certificación LEED</p>
                        </div>
                    </div>
                    <DetailLogo _name=' ' _title="El futuro es verde y con Calorex serás parte de él."></DetailLogo>
                </div>
                <div className="detail_leed_body_right detail_leed_item">
                <div className="detail_leed_body_right_imagecontainer">
                    <img src="/assets/images/model_detail/leed/calorex_detalle_modelo_producto_leed_imagen.png" alt="leed genera"></img>
                </div> 
                </div>
            </div>
            
        </div> : null

    )
}
export default ModelLeed