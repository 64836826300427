import React from 'react'
import DetailLogo from '../detail_logo';
import GetBodyItem from '../../../components/_helpers/_get_body_item'

import { Picture } from 'react-responsive-picture';

function ModelHeader(_props){
    let modelo = _props._model;
    let getBodyItem = new GetBodyItem();
    let data = getBodyItem.get_item(modelo,"PrismicModelBodyBannerApp")
    let items =null;
    if(data) {
        items = data.items;
    }
    if(modelo===undefined)
    {
        console.log('ERROR 3-------->',modelo);
        console.log('<--------');
        return (<></>)
    }
    else{
        if(modelo.data===undefined){
            console.log('ERROR 4-------->',modelo);
            console.log('<--------');
            return (<></>)
        }
    }
    return ( data ?
        <div className="detail_banner_app model_detail_section">
            <div className="detail_banner_app_header_container">
                <DetailLogo
                    _name={modelo.data.model_name.html}
                >
                </DetailLogo>
                <p className="detail_banner_app_header_title">Mi Calorex</p>
            </div>
            <div className="detail_banner_cel_container">
                <div className="detail_banner_bottom">
                    <div className="detail_banner_app_header_button_container">
                        {items.map((element,_index)=>{
                            return <a key={`detail_banner_app_item_${_index}`} href={element.app_link.text} target="_blank" rel="noopener noreferrer">
                                <Picture src={element.link_image.url} alt={element.link_image.alt}/>
                            </a>
                        })
                        }
                    </div>
                </div>
                <Picture
                    sources = {[
                    {
                        srcSet: data.primary.app_image.sm.url,
                        media: "(max-width: 767px)",
                    },
                    {
                        srcSet: data.primary.app_image.md.url,
                        media: "(max-width: 1366px)",
                    },
                    {
                        srcSet: data.primary.app_image.url,
                        media: "(min-width: 1367px)",
                    }
                    ]}
                />
            </div>
        </div> : null
    )
}
export default ModelHeader
