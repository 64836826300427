import React,{useEffect,useRef,useState} from 'react'
import DetailLogo from '../detail_logo';
import { Picture } from 'react-responsive-picture';
import { TextGradient } from '../../../components/_text/_text_gradient';

import CalorexDesign from '../../../components/svg/commons/calorex_design'

function ModelApp(_props){
    /* eslint-disable */
    let modelo = _props._model;
    const [scrollStart, setScrollStart] = useState();
    const detail_header = useRef(null);
    const detail_header_top = useRef(null);
    const detail_header_title = useRef(null);
    const [height, setHeight] = useState(0);
    // let global_top =0;
    // let lastScrollY = 0;


    useEffect(()=>{
        if(window!==undefined)
        {
            const winWidth =window.innerWidth;
            if(winWidth<768){
                setScrollStart('xs');
            }
            else{
                if(winWidth<1024){
                    setScrollStart('sm');
                }else{
                    if(winWidth<1366){
                        setScrollStart('md');
                    }else{
                        if(winWidth<1920){
                            setScrollStart('lg');
                        }
                        else{
                            setScrollStart('xlg');
                        }
                    }
                }
            }
        }
    },[]);

    useEffect(()=>{
        let fixed = false
        let scroll_total = 0;
        let top_total = 0;
        setHeight(document.documentElement.scrollHeight - document.documentElement.clientHeight)
        switch(scrollStart){
            case 'xs':
                scroll_total = -156;
                // scroll_total = -350;
                top_total = -156;
                break;
            case 'sm':
                scroll_total = -398;
                // scroll_total = -500;
                top_total = -398;
                break;
            case 'md':
                scroll_total = -528;
                // scroll_total = -500;
                top_total = -528;
                break;
            case 'lg':
                scroll_total = -366;
                // scroll_total = -500;
                top_total = -366;
                break;
            case 'xlg':
                scroll_total = -509;
                // scroll_total = -650;
                top_total = -529;
                break;
            default:
            break
        }
        if(scrollStart!==undefined)
        {
            // console.log('bind');

            window.addEventListener('scroll', ()=>{
                if(detail_header_top.current===null)return;
                let lastScrollY = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
                lastScrollY = lastScrollY *-1;
                // console.log('lastScrollY',lastScrollY);

                if(lastScrollY>scroll_total){
                    fixed=false;
                    let top = parseInt ( ( lastScrollY * top_total ) / scroll_total);
                    detail_header_top.current.style.transform= "translateY("+top+"px)";
                }
                else{
                    if(!fixed && lastScrollY>scroll_total)
                    {
                        fixed = true;
                        // global_top = top_total
                        detail_header_top.current.style.transform= "translateY("+top_total+"px)";
                    }
                }
            })
        }
    },[scrollStart]);

    function get_subtitle() {
        return(
            <div className="detail_header_subtitle" >
                { modelo.data.svg_icon ? <div className={"detail_header_subtitle_logo"} dangerouslySetInnerHTML={{__html: modelo.data.svg_icon}} /> : null}        
                {
                    modelo.data.model_name.html && 
                    <div className="detail_header_subtitle_p" dangerouslySetInnerHTML={{ __html: modelo.data.model_name.html }} />
                } 
            </div>
        )
        
    }
    function get_calorex_design_logo(){
        return(
            <div className="logo_calorex_design">
                <CalorexDesign />
                <div className="logo_calorex_design_line"></div>
            </div>
        )
        
    }
    let class_calorex_design =  modelo.data.calorex_design ? 'detail_calorex_design' : '';
    
    return (
        <div className={ `detail_header detail_header--fixed ${class_calorex_design}`} ref={detail_header}>
            <div className="detail_header_top" ref={detail_header_top}>
                <div className="detail_header_title" ref={detail_header_title}>
                    { modelo.data.calorex_design ? get_subtitle() : ''}
                    <div className="detail_header_title_container" >
                        { modelo.data.calorex_design ?
                            <TextGradient _text={modelo.data.claim.text} _deg='90' _color_start="#ffffff" _color_end="#ffffff"></TextGradient>
                            : 
                            <TextGradient
                                _text={modelo.data.claim.text}
                                _deg='90'
                                _color_start={modelo.data.title_color_start}
                                _color_end={modelo.data.title_color_end}
                            >
                            </TextGradient>
                        }
                    </div>
                    { !modelo.data.calorex_design ? get_subtitle() : ''}
                    { modelo.data.calorex_design ? get_calorex_design_logo() : ''}
                </div>
                
                <div className="detail_header_bg">
                    <Picture
                        sources = {[
                        {
                            srcSet: modelo.data.main_image_xs.url+'&q=90',
                            media: "(max-width: 767px)",
                        },
                        {
                            srcSet: modelo.data.main_image_sm.url+'&q=90',
                            media: "(max-width: 1023px)",
                        },
                        {

                            srcSet: modelo.data.main_image_md.url+'&q=90',
                            media: "(max-width: 1366px)",
                        },
                        {

                            srcSet: modelo.data.main_image_lg.url+'&q=90',
                            media: "(max-width: 1919px)",
                        },
                        {
                            srcSet: modelo.data.main_image_xlg.url+'&q=90',
                            media: "(min-width: 1920px)",
                        }
                        ]}
                    />
                </div>
            </div>
            <div className="detail_header_info">
                <div className="detail_header_info_stage">
                    <DetailLogo
                        _name={modelo.data.model_name.html}
                    >
                    </DetailLogo>
                    <TextGradient _className="detail_header_info_copy" _text={modelo.data.ideal_copy.text} ></TextGradient>
                    <p className="detail_header_info_list">{modelo.data.ideal_list.text}</p>
                </div>
            </div>
        </div>
    )
}
export default ModelApp
