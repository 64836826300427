import React from 'react'
import { gsap, TimelineMax } from 'gsap'
import CssClassManager from '../_helpers/_css_class_manager'

import ArrowHead from '../svg/commons/arrow_head'

class VersionsMenuC extends React.Component{
    versions = [];
    currentVersion = null;
    itemsRef = [];
    cssClassManager = new CssClassManager();
    product = null;
    family = '';
    menu_busy = false;
    currentIndex = 0;
    sliderRef = null;

    constructor(props){
        super(props);
        this.versions = props._versions
        this.product = props._item;
        this.family =props._family
        this.handleItemClick = props.handleItemClick;
        gsap.registerPlugin(TimelineMax);
        this.sliderRef = document.getElementsByClassName('detail_version_menu_item__slider');
    }

    clickMenuVersion(_version,_index){
        if(this.menu_busy) return;
        let new_item = this.itemsRef[_index];
        if(this.cssClassManager.has_class(new_item,'detail_version_menu_item--current')){
            return(false);
        }

        switch(this.versions.length)
        {
            case 1:
                break;
            case 2: let current_element = _index !==0 ? this.itemsRef[0] : this.itemsRef[1];
                this.cssClassManager.remove_class(current_element,'detail_version_menu_item--active2')
                this.cssClassManager.add_class(this.itemsRef[_index],'detail_version_menu_item--active2')
                this.handleItemClick(this.versions[_index]);
            break;
            case 3:
                this.cssClassManager.remove_class(this.sliderRef[0],`detail_version_menu_item--selected_${this.currentIndex}`);
                this.cssClassManager.add_class(this.sliderRef[0],`detail_version_menu_item--selected_${_index}`);
                this.currentIndex = _index;
                this.handleItemClick(this.versions[_index]);
            break;
            default: let go_up = this.cssClassManager.has_class(new_item,'detail_version_menu_item--next') ? true : false;
                this.MoveSlider(go_up);
                break;
        }
    }

     MoveSlider(go_up){
        if(this.menu_busy) return false;
        this.menu_busy = true;
        let current_item = this.itemsRef[this.currentIndex];
        let _index = 0;
        let prev_index  = this.currentIndex-1>=0 ? this.currentIndex-1 : this.versions.length-1;
        let prev_item = this.itemsRef[prev_index];

        let next_index = this.currentIndex+1<=this.versions.length-1 ? this.currentIndex+1 : 0;
        let next_item  = this.itemsRef[next_index];
        let item_in = null;
        let item_index = null;
        let top_animator_item = null;
        let bottom_animator_item = null;
        if(go_up)
        {
            item_index = ((this.currentIndex + 2) % this.versions.length);
            _index = next_index;
            top_animator_item = next_item;
            bottom_animator_item = prev_item;
            this.handleItemClick(this.versions[_index]);
        }
        else{
            item_index = this.currentIndex-2>=0 ? this.currentIndex-2 : this.versions.length+(this.currentIndex-2);
            _index = prev_index;
            top_animator_item = prev_item ;
            bottom_animator_item = next_item;
            this.handleItemClick(this.versions[_index]);
        }
        let y_direction = go_up ? 1 : -1 ;

        item_in = this.itemsRef[item_index];

        let y_in = 210 * y_direction;
        let y_out = 105 * y_direction;

        let myTween = new TimelineMax({paused: true,onStart: ()=>{
            if(go_up){
                // console.log('up________')
                this.cssClassManager.remove_class(next_item,'detail_version_menu_item--next')
                this.cssClassManager.add_class(next_item,'detail_version_menu_item--current')

                this.cssClassManager.remove_class(current_item,'detail_version_menu_item--current')
                this.cssClassManager.add_class(current_item,'detail_version_menu_item--prev')

                this.cssClassManager.remove_class(prev_item,'detail_version_menu_item--prev')

                this.cssClassManager.add_class(item_in,'detail_version_menu_item--next')
            }
            else{
                // console.log('down________')
                this.cssClassManager.remove_class(next_item,'detail_version_menu_item--next')

                this.cssClassManager.remove_class(current_item,'detail_version_menu_item--current')
                this.cssClassManager.add_class(current_item,'detail_version_menu_item--next')

                this.cssClassManager.remove_class(prev_item,'detail_version_menu_item--prev')
                this.cssClassManager.add_class(prev_item,'detail_version_menu_item--current')

                this.cssClassManager.add_class(item_in,'detail_version_menu_item--prev')
            }
        },onComplete:()=>{
            this.menu_busy = false;
            this.currentIndex=_index;
        }
    });
    myTween
        .fromTo(top_animator_item,1.0,{y: (105*y_direction+'px')},{y: 0})
        .fromTo(current_item,1.0,{y: '0px'},{y: (-105*y_direction+'px')},'-=1.0')
        .fromTo(bottom_animator_item,1.0,{y: (-105*y_direction+'px')},{y: (-210*y_direction+'px')},'-=1.0')
        .fromTo(item_in,1.0,{y: y_in},{y: y_out+'px'},'-=1.0')
        .play();
    }

    getMenuItem(_item, _control_class,_index,_dash = 7){
        // console.log('_index-------->',_index);
        // // let class_string= currentVersion ===  _item.uid ? _control_class+' detail_version_menu_item--current' : _control_class;
        // console.log('_item',_item);
        // console.log(`menu_version_item_${_item.uid}`);

        let class_string= _control_class
        let dash_items = [];

        for(let i =1;i<=_dash;i++){
            dash_items.push(<div key={`menu_version_item_${_index}_${i}`} className={`detail_version_menu_item_dash detail__bggradient__color detail_version_menu_item_dash_${i}`}></div>)
        }

        return(

                <div className={`detail_version_menu_item detail_version_menu_item_${_item.uid} ${class_string} detail_version_menu_item_${_index}`}
                key={`menu_version_item_${_index}`} onClick={()=>this.clickMenuVersion(_item.uid,_index)}
                ref={el => this.itemsRef[_index] = el}
                >
                    { dash_items }

                    <div className="detail_version_menu_item_content">
                        <div className="detail_version_menu_item_type">{this.family}</div>
                        <div className="detail_version_menu_item_name detail_color_start">{_item.data.title.text}</div>
                    </div>
                </div>
        )
      }

      getMenuItemsTwo(_versions){
        return (
        <>
          {this.getMenuItem(_versions[0],'detail_version_menu_item--middle2 detail_version_menu_item--active2',0,10)}
          {this.getMenuItem(_versions[1],'detail_version_menu_item--prev2',1,10)}
        </>
        )
      }
      getMenuItemsThree(_versions){
        return (
        <>
          {this.getMenuItem(_versions[0],'detail_version_menu_item--middle3 detail_version_menu_item--active3',0)}
          {this.getMenuItem(_versions[1],'detail_version_menu_item--next3',1)}
          {this.getMenuItem(_versions[2],'detail_version_menu_item--prev3',2)}
        </>
        )
      }
      getMenuItemsSlider(_versions){
        let count_2 = 0;
        let index =0;

        _versions.map((_version)=>{
         if(_version.uid === this.currentVersion){
           return index = count_2;
          }
          else{
          return  count_2++;
          }
        });

        let count =-1;
        // eslint-disable-next-line
        return _versions.map((_version)=>{
            count++;
            if(count===index){
                return (this.getMenuItem(_versions[count],'detail_version_menu_item--current',count))
            }else{
                if(index===0){
                    if(count===_versions.length-1){
                        return (this.getMenuItem(_versions[_versions.length-1],'detail_version_menu_item--prev',count))
                    }
                    else{
                        if(count===1){
                            return (this.getMenuItem(_versions[count],'detail_version_menu_item--next',count))
                        }
                        else{
                            return (this.getMenuItem(_versions[count],'',count))
                        }
                    }
                }
                else{
                    if(index===_versions.length-1){
                        if(count===0)
                        {
                            return (this.getMenuItem(_versions[count],'detail_version_menu_item--next',count))
                        }else{
                            if(count===_versions.length-2)
                            {
                                return (this.getMenuItem(_versions[_versions.length-2],'detail_version_menu_item--prev',count))
                            }
                        }
                    }
                    else{
                        if(count===index+1)
                        {
                            return (this.getMenuItem(_versions[count],'detail_version_menu_item--next',count))
                        }
                        else{
                            if(count===index-1)
                            {
                                return (this.getMenuItem(_versions[count],'detail_version_menu_item--prev',count))
                            }
                            else{
                                return (this.getMenuItem(_versions[count],'',count))
                            }
                        }

                    }
                }
            }
        });
      }
      getItems(_versions){
        // console.log('_versions.length',_versions.length);
        switch(_versions.length){
          case 0: return (<></>);
      
          case 1: return this.getMenuItem(_versions[0],'detail_version_menu_item--active1 detail_version_menu_item--current',0,13);
         
          case 2: return this.getMenuItemsTwo(_versions);
         
          case 3: return this.getMenuItemsThree(_versions);
         
          default: return this.getMenuItemsSlider(_versions);
         ;
        }
    }
    render() {
        return (
            <div className="detail_version_menu">
                <div className="detail_version_menu_stage">
                {this.versions.length>3 ?
                    <div className="detail_version_menu_prev detail_version_menu_nav" onClick={()=>{this.MoveSlider(true)}}>
                        <ArrowHead />
                    </div> : ''
                }
                <div className="detail_version_menu_item_container">
                    <div className="detail_version_menu_item__mask">
                        <div className="detail_version_menu_item__slider detail_version_menu_item--selected_0">
                            {this.getItems(this.versions)}
                        </div>
                    </div>
                </div>
                {this.versions.length>3 ?
                    <div className="detail_version_menu_next detail_version_menu_nav" onClick={()=>{this.MoveSlider(false)}}>
                        <ArrowHead />
                    </div>: ''
                }
                </div>
            </div>
        )
    }
}

export default VersionsMenuC;