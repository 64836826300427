import React, { useEffect, useState } from 'react';
import badge from '../../../../static/assets/images/green_foam/badge-green-foam.png';

export const ModelGreenFoam = props => {

    const [isActive, setIsActive] = useState(false);

    useEffect( () => {
        /* eslint-disable */
        let _model = props._model;

        if (!_model.data.body) return;

        _model.data.body.map(item =>{
            if(item.__typename === "PrismicModelBodyGreenFoam"){
               return setIsActive(item.primary.green_foam_active==="activo");
            }
        })
    },[]);

    return ( isActive ?
        <div className="detail_green_foam">
            <div className="detail_green_foam_logo">
                <img src={ badge } alt="Badge Green Foam"></img>
            </div>
        </div> : null

    )
}