import React,{useState,useEffect} from 'react'

import VersionsCarousel from '../../../components/_versions/_versions_carrousel';
// import VersionsMenu from '../../../components/_versions/_versions_menu';
import VersionsMenuC from '../../../components/_versions/_versions_menuc';

import DetailLogo from '../detail_logo';


import Dropdown from '../../../components/_comparator/dropdown'


function ModelDimensions(props){
    
    const [product, changeProduct] = useState( props._versions[0])
    const [versionsArray, setVersionsArray] = useState([]);
    let item = props._item;
    let versions = props._versions;    

    useEffect(()=>{
        let versions_array = [];
        let first_item = null;
        
        if(versions!==undefined)
        {
             versions.map((element)=>{        
     
                versions_array.push(
                    { 
                        'uid': element.uid, 
                        'order': element.data.sort_index,
                        'data':{
                            'title':{
                                'text': element.data.title.text
                            }
                        }
                    });

                if(first_item===null)
                {
                    return  first_item = element
                }else return null
            })

            versions_array.sort(function(a, b) {
                return a.order - b.order;
            });            
            setVersionsArray(versions_array)
        }
        
        changeProduct(versions_array[0]);
    },[versions])
    
 
    function onMenuChangeProduct(item)
    {
        changeProduct(item);
    }    
    function onChangeProduct(item,dropdownP) {
        changeProduct(item);
        if(dropdownP!==undefined){
            dropdownP.current.style.opacity=0;
        }
        
        setTimeout(()=>{
            if(dropdownP!==undefined){
                dropdownP.current.style.opacity=1;
            }
            
        },30)
    }
    
    
    return (
        <div className="detail_dimensions model_detail_section">
            <div className="detail_dimensions_header">
                <DetailLogo _name={item.data.model_name.html} _title="Dimensiones"></DetailLogo>
            </div>
            
            <div className="detail_dimensions_body">
                <div className="detail_dimensions_select_container">                    
                    <Dropdown 
                        default_item={product.data.title.text} 
                        items={versionsArray} 
                        handleItemClick={onChangeProduct} 
                        type={2}
                        section='dimension_gradient'
                    />
                    
                </div>
                <div className="products__carousel">
                    {versionsArray.length>=1 ?
                        <VersionsMenuC _versions={versionsArray} _item={item} handleItemClick={onMenuChangeProduct} _family={item.data.category.document[0].data.name.text}/> : ''
                    }
                    <VersionsCarousel _versions={versions} _product={product.uid} _family={item.data.category.document[0].data.name.text}/>
                </div>
            </div>
        </div>

    )
}
export default ModelDimensions
