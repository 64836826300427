import React, { useRef, useEffect, useState } from "react"
import Slider from "react-slick"
import GetBodyItem from "../../../components/_helpers/_get_body_item"
import Youtube from "../../../components/video_youtube/youtube"
import SliderArrow from "../../../components/sliders/slider_arrow"
import { SliderSlickButton } from "../../../components/buttons/_button_slider_slick"

const ModelYoutube = _props => {
  const sliderYoutube = useRef(null)

  let modelo = _props._model
  let getBodyItem = new GetBodyItem()
  let data = getBodyItem.get_item(modelo, "PrismicModelBodyYoutube")
  let sliderArrow = new SliderArrow()
  const bannerSectionRef = useRef(null)
  const [items, setItems] = useState(null)

  const settings = {
    customPaging: function(i) {
      return <span className="pagination__bullet"></span>
    },
    dots: true,
    dotsClass: "pagination__dots",
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderSlickButton _className="slick-button-prev" />,
    nextArrow: (
      <SliderSlickButton _className="slick-button-next" isNext={true} />
    ),
  }

  
  useEffect(() => {
    if (data && data.items) {
      setItems(data.items)
    }
  }, [items])

  useEffect(() => {
    if (!bannerSectionRef.current) return
    sliderArrow.init(bannerSectionRef)
  }, [sliderArrow])

  function create_items(_items) {
    return _items.map((_item, _index) => {
      return (
        <div key={`detail_youtube_item_${_index}`} className="">
          <Youtube
            title={_item.title.text}
            description={_item.description.text}
            url={_item.video_url.embed_url}
          />
        </div>
      )
    })
  }
  return items !== null && (
    <div
      className="model_detail_section detail_youtube "
      ref={bannerSectionRef}
    >
      <div className="detail_youtube_stage">
        <div className="detail_youtube_slider">
          <div
            className={`banner__prev cursor__change`}
            onClick={() => sliderYoutube.current.slickPrev()}
          ></div>
          <div
            className={`banner__next cursor__change`}
            onClick={() => sliderYoutube.current.slickNext()}
          ></div>
          <Slider ref={sliderYoutube} {...settings}>
            {create_items(items)}
          </Slider>
        </div>
      </div>
    </div>
  )
}
export default ModelYoutube
