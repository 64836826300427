import React from 'react'
import { Picture } from 'react-responsive-picture';

function ModelVideo(_props){

    let _model = _props._model;
    let body =  _model.data.body;

    let found = false;
    let item_count = 0;
    let video_banner = null;

    while (!found && item_count<body.length)
    {
        let item = body[item_count];
        if(item.__typename==="PrismicModelBodyBannerVideo")
        {
            found = true;
            video_banner = item.primary;
        }
        item_count++;
    }
    return ( found ?

        <div className="detail_video model_detail_section">
            <div className="detail_video_stage">
                <Picture
                    sources = {[
                    {
                        srcSet: video_banner.banner_image_xs.url,
                        media: "(max-width: 768px)",
                    },
                    {
                        srcSet: video_banner.banner_image_sm.url,
                        media: "(max-width: 1366px)",
                    },
                    {
                        srcSet: video_banner.banner_image_xlg.url,
                        media: "(max-width: 1920px)",
                    },
                    {
                        srcSet: video_banner.banner_image_xlg.url,
                        media: "(min-width: 1920px)",
                    }
                    ]}
                />
            </div>
        </div> : null
    )
}
export default ModelVideo