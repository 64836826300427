import React,{useRef, useEffect} from 'react'
import { TextGradient } from '../../../components/_text/_text_gradient';
import Slider from 'react-slick';
import GetBodyItem from '../../../components/_helpers/_get_body_item'
import { Picture } from 'react-responsive-picture';
import SliderArrow from '../../../components/sliders/slider_arrow';
import { SliderSlickButton } from '../../../components/buttons/_button_slider_slick';

function ModelSystems(_props){
    let modelo = _props._model;
    const swiperSystem = useRef(null)
    let getBodyItem = new GetBodyItem();
    let data = getBodyItem.get_item(modelo,"PrismicModelBodySistemas")
    let items = null;
    let sliderArrow = new SliderArrow();
    const bannerSectionRef = useRef(null)

    if(data) {
        items = data.items;
    }

    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SliderSlickButton _className="slick-button-prev"/>,
        nextArrow: <SliderSlickButton _className="slick-button-next" isNext={true}/>
    };

    useEffect(()=>{
        if(!bannerSectionRef.current) return;
        sliderArrow.init(bannerSectionRef);
    },[sliderArrow])

    function create_items(_items){
        return _items.map((_item,_index)=>{
            return (
                <div key={`detail_system_item_${_index}`} className="detail_system_items_container">
                    <div className="detail_system_title_container">
                        <p className="detail_system_title detail_main_color">{_item.systems_title.text}</p>
                        <TextGradient _className="detail_system_description" _text={_item.systems_description.text}></TextGradient>
                    </div>
                    <div className="detail_system_animation_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: _item.systems_image.xs.url,
                                media: "(max-width: 767px)",
                            },
                            {
                                srcSet: _item.systems_image.lg.url,
                                media: "(max-width: 1920px)",
                            },

                            {
                                srcSet: _item.systems_image.url,
                                media: "(min-width: 1920px)",
                            }
                            ]}
                        />
                    </div>
                </div>
            )
        });
    }
    return ( data ?
        <div className="detail_system model_detail_section" ref={bannerSectionRef}>
            <div className="detail_system_stage">
                <div className="detail_system_slider">
                    <div className={`banner__prev cursor__change`} onClick={() => swiperSystem.current.slickPrev()} ></div>
                    <div className={`banner__next cursor__change`} onClick={() => swiperSystem.current.slickNext()} ></div>
                    <Slider ref={swiperSystem}  {...settings}>
                        {create_items(items)}
                    </Slider>
                </div>
            </div>
        </div>:null
    )
}
export default ModelSystems