import React,{useState, useEffect} from 'react'

// import { useCurrenDevice } from "../../hooks/useCurrentDevice";

import { Picture } from 'react-responsive-picture';


function VersionsCarousel( props ) {    
    
    const [filter, setFilter] = useState(props._product)
    const [item, setItem] = useState()
    
    
    useEffect(() => {
      
      setFilter(props._product)
    }, [props._product])

  
    useEffect(() => {
      let found = false;
      let count= -1;
      while(!found || count<props._versions.length -1)
      {
        count ++;
        let data = props._versions[count];
          
        if(data.uid === filter){
                 
          setItem(data);
          found= true;          
        }
      }
      
    }, [filter, props._versions])

    
    useEffect(()=>{
      // console.log("ITEM -----> ", item)
    },[item])
    
    

    return item!==undefined ? (
      
      <div className="detail_version_item_container" >
        
        <div className="detail_version_item" key={`detail_version_${item.uid}`}>
          
          <div className="detail_version_bgcontainer">
            <div className="detail_version_bg detail_version_itembg">
              {item.data.image_bg!==null ? 
              <Picture
                sources = {[
                {
                    srcSet: item.data.image_bg.sm.url,
                    media: "(max-width: 768px)",
                },
                {
                    srcSet: item.data.image_bg.lg.url,
                    media: "(max-width: 1920px)",
                },  
                {
                  srcSet: item.data.image_bg.lg.url,
                  media: "(min-width: 1921px)",
                }    
                ]}
              />
              
              : null
              }
            </div>
            
          </div>
          <div className="detail_version_info_container">
            <div className="detail_version_info_container_stage">
              {item.data.body[0].items.map((element,key)=>{
                return (
                  <div className="detail_version_info_item" key={`detail_version_info_item_${key}`}>
                    <div className="detail_version_info_stage" key={`detail_version_info_item_${key}`}>
                      {/* <div className="detail_version_info_img">
                        <img src={element.summary_img.url}></img>
                      </div> */}
                      <div className="detail_version_info_text" dangerouslySetInnerHTML={{__html: element.description.html}}></div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          {
            item.data.body[0].primary.bottom_text.html &&
              <div className="detail_version_info_container_bottom-text" dangerouslySetInnerHTML={{__html: item.data.body[0].primary.bottom_text.html}}></div>
          }
        </div>
      </div>
    ) : null   
}

export default VersionsCarousel
