import React from 'react'
import CalorexDesign from '../../../components/svg/commons/calorex_design'

import { Picture } from 'react-responsive-picture';

function ModelCalorexDesign(_props){
    // let modelo = _props._model;
    
    
    // let items =null;
    
    return (
        <div className="detail_calorex_design model_detail_section">
            <div className="detail_calorex_design_header">
                <div className="detail_calorex_design_headercontainer">
                    <div className="logo_calorex_design">
                        <CalorexDesign />
                        <div className="logo_calorex_design_line"></div>
                    </div>
                    <p className="detail_calorex_design_header_title">Elevamos nuestra innovación, tecnología y diseño a un nuevo nivel.</p>
                </div>
                
            </div>
            <div className="detail_calorex_design_body">
                
                
                <Picture
                    sources = {[
                    {
                        srcSet: '/assets/images/model_detail/calorex_design/calorex_detalle_modelo_calorex_design_320.png',
                        media: "(max-width: 767px)",
                    },
                    {
                        srcSet: '/assets/images/model_detail/calorex_design/calorex_detalle_modelo_calorex_design_768.png',
                        media: "(max-width: 1023px)",
                    },
                    {
                        srcSet: '/assets/images/model_detail/calorex_design/calorex_detalle_modelo_calorex_design_1024.png',
                        media: "(max-width: 1365px)",
                    }
                    ,
                    {
                        srcSet: '/assets/images/model_detail/calorex_design/calorex_detalle_modelo_calorex_design_1366.png',
                        media: "(max-width: 1919px)",
                    }
                    ,
                    {
                        srcSet: '/assets/images/model_detail/calorex_design/calorex_detalle_modelo_calorex_design_1920.png',
                        media: "(min-width: 1920px)",
                    }
                    ]}
                />
            </div>
        </div> 
    )
}
export default ModelCalorexDesign
