import React from "react"
import DetailLogo from "../../content/model_detail/detail_logo"

import ReactPlayer from "react-player"

const Youtube = props => {
  return (
    <div className="video_youtube_container">
      <div className="video_youtube">
        <div className="player">
          <ReactPlayer
            url={props.url}
            controls={true}
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <div className="info_container">
        <DetailLogo className="detail_header_logo_container" />
        <h3 className="title_video_container">{props.title}</h3>
        <p>{props.description}</p>
      </div>
    </div>
  )
}

export default Youtube
