import React,{useRef,useEffect} from 'react'
import { Link } from 'gatsby'

import DataSheet from '../../../components/svg/commons/data_sheet'
import Maintenance from '../../../components/svg/maintenance/maintenance'
import Manual from '../../../components/svg/commons/manual'
import Distribuidores from '../../../components/svg/maintenance/dealers'

import CssClassManager from '../../../components/_helpers/_css_class_manager'

function ModelMenu(_props){
    let _model = _props._model;
    
    let _section = 'menu_svg_'
    const menu = useRef(null);
    let body =  _model.data.body;

    let found = false;
    let item_count = 0;
    let link_technical = null;
    let link_manual = null;
    // const hided = true;
    let valueScroll = 0;
    let cssClassManager = new CssClassManager()

    while (!found && item_count<body.length)
    {
        let item = body[item_count];
        if(item.__typename==="PrismicModelBodyArchivos")
        {
            found = true;
            link_technical = item.primary.technical.url
            link_manual = item.primary.manual.url
        }
        item_count++;
    }

    useEffect(()=>{
        // hided = true;
        /* eslint-disable */
        if(window!==undefined)
        {
            window.addEventListener('scroll', listenToScroll)
        }
    },[])


    function listenToScroll () {

        if(menu===null) return false;

        const winScroll =document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
        const scrolled = winScroll / height


        if( scrolled > 0.01755997958141909){
            if( scrolled > valueScroll){
                cssClassManager.add_class(menu.current,'detail_header_nav--visible')
                valueScroll = scrolled;
            }else{
                cssClassManager.remove_class(menu.current,'detail_header_nav--visible')
                valueScroll = scrolled;
            }
        }
    }

    return (
        <div className="detail_header_nav" ref={menu}>
            <style dangerouslySetInnerHTML={{__html: `
                .detail_header_nav_technical:hover svg{ fill: url(#${_section}_datasheet_animation)}
                .detail_header_nav_maintenance:hover svg{ fill: url(#${_section}_maintenance_animation)}
                .detail_header_nav_manual:hover svg{ fill: url(#${_section}_manual_animation)}
                .detail_header_nav_dealers:hover svg{ fill: url(#${_section}_dealers_animation)}
            `}} />
            <div className="detail_header_nav_stage">
                { link_technical!==null ?
                    <a className="detail_header_nav_item detail_header_nav_technical product__bg__gradient--h" href={link_technical} target="_blank" rel="noopener noreferrer">
                        <div className="detail_header_nav__logo">
                            <DataSheet fillstart={"#ba0c2f"} fillend={"#d90b2d"} section={_section} fill={`url(#${_section}_datasheet)`}></DataSheet>
                        </div>
                        <p className="detail_header_nav__info">Ficha técnica</p>
                    </a> : null
                }
                <Link className="detail_header_nav_item detail_header_nav_maintenance product__bg__gradient--h" to='/solicita-ayuda'>
                    <div className="detail_header_nav__logo">
                        <Maintenance fillstart={"#ba0c2f"} fillend={"#d90b2d"} section={_section} fill={`url(#${_section}_maintenance)`}></Maintenance>
                    </div>
                    <p className="detail_header_nav__info">Solicita ayuda</p>
                </Link>
                { link_manual!==null ?
                    <a className="detail_header_nav_item detail_header_nav_manual product__bg__gradient--h" href={link_manual} target="_blank" rel="noopener noreferrer">
                        <div className="detail_header_nav__logo">
                            <Manual fillstart={"#ba0c2f"} fillend={"#d90b2d"} section={_section} fill={`url(#${_section}_manual)`}></Manual>
                        </div>
                        <p className="detail_header_nav__info">Manual</p>
                    </a>: null
                }
                <Link className="detail_header_nav_item detail_header_nav_dealers product__bg__gradient--h" to={_model.data.category.uid==="comerciales" ? '/distribuidores-comerciales' : '/distribuidores' }>
                    <div className="detail_header_nav__logo">
                        <Distribuidores fillstart={"#ba0c2f"} fillend={"#d90b2d"} section={_section} fill={`url(#${_section}_dealers)`}></Distribuidores>
                    </div>
                    <p className="detail_header_nav__info">Distribuidores</p>
                </Link>
            </div>
        </div>
    )
}
export default ModelMenu