import React from 'react'

const DataSheet = props => (
  <svg
    id="prefix__Capa_2"
    x={0}
    y={0}
    viewBox="0 0 31.9 36"
    xmlSpace="preserve"
    {...props}
  >
    
    <path
      d="M20.2 5.3H6.3v25.3h19.3V10.4l-5.4-5.1zm.4 2.2l3 2.8h-3V7.5zm-13 21.8V6.6h11.7v4c0 .5.4 1 .9 1h4.1v17.7H7.6z"
    />
    <path      
      d="M21.4 13.8h-11c-.3 0-.5.2-.5.5s.2.5.5.5h11c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zm0 3.7h-11c-.3 0-.5.2-.5.5s.2.5.5.5h11c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zm0 3.6h-11c-.3 0-.5.2-.5.5s.2.5.5.5h11c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zm-7 3.7h-4c-.3 0-.5.2-.5.5s.2.5.5.5h4c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"
    />
    {props.section!==undefined ? 
              
      <>
      <linearGradient id={`${props.section}_datasheet`}>
        <stop offset="2%" stopColor={props.fillstart} />
        <stop offset="100%" stopColor={props.fillend} />
      </linearGradient>
      <linearGradient id={`${props.section}_datasheet_animation`}>
        <stop offset="0%" stopColor={props.fillend} >            
        </stop>
        <stop offset="1%" stopColor={props.fillstart} >
          <animate attributeName="offset"   values=".01; .01; .01; .25; .5;  .75; .85;.98; .85;.75;.5;.25;.01;.01;0.1;" repeatCount="indefinite" dur="2s" />
        </stop>
        <stop offset="2%" stopColor={props.fillstart} >
            <animate attributeName="offset" values=".02; .25; .5;   .75; .99; .99; .99;.99;.99;.99;.99;.75;.5;0.25;0.2" repeatCount="indefinite" dur="2s" />
        </stop>
        <stop offset="100%" stopColor={props.fillend} >          
        </stop>
      </linearGradient>
      </>
      : null 
    }
  </svg>
)

export default DataSheet