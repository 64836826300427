import React, { useEffect, useState } from 'react';
import badge from '../../../../static/assets/images/nom/badge_nom_128.png';

export const ModelNOMBadge = props => {

    const [isActive, setIsActive] = useState(false);

    useEffect( () => {
        /* eslint-disable */
        
        let _model = props._model;

        if (!_model.data.body) return;

        _model.data.body.map(item =>{
            if(item.__typename === "PrismicModelBodyNomBadge"){                
               return setIsActive(item.primary.nom_badge_active===true);
            }
        })
    },[]);

    return ( isActive ?
        <div className="detail_nom_badge">
            <div className="detail_nom_badge_logo">
                <img src={ badge } alt="Badge NOM"></img>
            </div>
        </div> : null

    )
}