import React, { useEffect, useState } from "react"
import Layout from "../components/layout"

import ModelHeader from "../content/model_detail/_sections/_model_header"
import ModelAdvantages from "../content/model_detail/_sections/_model_advantages"
import ModelYoutube from "../content/model_detail/_sections/_model_youtube"
import ModelDimensions from "../content/model_detail/_sections/_model_dimensions"
import ModelApp from "../content/model_detail/_sections/_model_app"
import ModelCalorexDesign from "../content/model_detail/_sections/_model_calorex_design"
import ModelSystems from "../content/model_detail/_sections/_model_systems"
import ModelConocelo from "../content/model_detail/_sections/_model_conocelo"
import ModelMaintenance from "../content/model_detail/_sections/_model_maintenance"
import ModelMenu from "../content/model_detail/_sections/_model_menu"

import HomeProducts from "../content/home/products/home_products"

import "../styles/pages/model/model.scss"

import ModelVideo from "../content/model_detail/_sections/_model_video"
import ModelLeed from "../content/model_detail/_sections/_model_leed"
import { ModelGreenFoam } from "../content/model_detail/_sections/_model_green_foam"
import { ModelNOMBadge } from "../content/model_detail/_sections/_model_nom_badge"
import { useCurrenDevice, DEVICES } from "../hooks/useCurrentDevice"
import { usePrismModels } from "../hooks/use_prism-models"
import { Link } from "gatsby"

import GetBodyItem from "../components/_helpers/_get_body_item"

const CalentadoresDetail = props => {
  let model = props.pageContext.item.node
  const allModels = usePrismModels().nodes
  if (model.data === undefined) {
    console.log("ERROR 2 ", model.uid)
  }
  let versions = props.pageContext.versions

  // console.log('model',model);

  const start_color = "#ba0c2f"
  const stop_color = "#d90b2d"
  const text_color = "#ba0c2f"

  const [device] = useCurrenDevice()
  const [familyModels, setFamilyModels] = useState([])
  let metas_from_prismic = new GetBodyItem().get_item(
    model,
    "PrismicModelBodyMetas"
  )

  let metas = {
    title: "Calorex modelo",
    description: "Calorex modelo",
    image: "",
  }
  if (metas_from_prismic) {
    metas.title = metas_from_prismic.primary.title.text
    metas.description = metas_from_prismic.primary.description.text
    metas.image = metas_from_prismic.primary.image_share.url
  }
  useEffect(() => { 
    if (device === DEVICES.desktop) return
    setFamilyModels(
      allModels.filter(
        item => item.data.category.uid === model.data.category.uid
      )
    )
    // console.log('familyModels --->', familyModels)
     // eslint-disable-next-line
  }, [device])

  return (
    <Layout _class="modeldetail">
      <style
        dangerouslySetInnerHTML={{
          __html: `.stage .main .swiper-pagination-bullet-active { border-color: ${start_color} }
                .detail_main_color { color: ${start_color} }
                .detail_version_menu_item_dash:before{ background-image: linear-gradient(${start_color} 0%, ${stop_color} 100%); }
                .pagination__dots .slick-active .pagination__bullet{ border-color: ${start_color}}
                .pagination__dots li:hover .pagination__bullet{ border-color: ${start_color}}
                .stage .main .detail_color_start{ color: ${start_color}}
                .select__family{background-image: linear-gradient(${start_color} 0%, ${stop_color} 100%);}

                .product__bg__gradient--h{background: linear-gradient(90deg, ${start_color} 0%, ${stop_color} 100%);}
                .product__bg__gradient--h{background: linear-gradient(90deg, ${start_color} 0%, ${stop_color} 100%);}
                .product__title_color{color: ${text_color} ;}
                // .detail_maintenance .access__button p{background: linear-gradient(90deg, ${start_color} 0%, ${stop_color} 100%);}
                .detail_maintenance .access__button p { animation: detail_maintenance_bg_animation_out 0.25s forwards;}
                .detail_maintenance .access__button:hover p { animation: detail_maintenance_bg_animation_in 0.25s forwards;}


                @keyframes detail_maintenance_bg_animation_in {
                    0%   { background: linear-gradient(90deg, ${start_color} 0%, ${stop_color} 100%);}
                    10%   { background: linear-gradient(90deg, ${start_color} 10%, ${stop_color} 100%);}
                    20%   { background: linear-gradient(90deg, ${start_color} 20%, ${stop_color} 100%);}
                    30%   { background: linear-gradient(90deg, ${start_color} 30%, ${stop_color} 100%);}
                    40%   { background: linear-gradient(90deg, ${start_color} 40%, ${stop_color} 100%);}
                    50%   { background: linear-gradient(90deg, ${start_color} 50%, ${stop_color} 100%);}
                    60%   { background: linear-gradient(90deg, ${start_color} 60%, ${stop_color} 100%);}
                    70%   { background: linear-gradient(90deg, ${start_color} 70%, ${stop_color} 100%);}
                    80%   { background: linear-gradient(90deg, ${start_color} 80%, ${stop_color} 100%);}
                    90%   { background: linear-gradient(90deg, ${start_color} 90%, ${stop_color} 100%);}
                    100%  { background: linear-gradient(90deg, ${start_color} 99%, ${stop_color} 100%);}
                }
                @keyframes detail_maintenance_bg_animation_out {
                    0%   { background: linear-gradient(90deg, ${start_color} 99%, ${stop_color} 100%);}
                    10%   { background: linear-gradient(90deg, ${start_color} 90%, ${stop_color} 100%);}
                    20%   { background: linear-gradient(90deg, ${start_color} 80%, ${stop_color} 100%);}
                    30%   { background: linear-gradient(90deg, ${start_color} 70%, ${stop_color} 100%);}
                    40%   { background: linear-gradient(90deg, ${start_color} 60%, ${stop_color} 100%);}
                    50%   { background: linear-gradient(90deg, ${start_color} 50%, ${stop_color} 100%);}
                    60%   { background: linear-gradient(90deg, ${start_color} 40%, ${stop_color} 100%);}
                    70%   { background: linear-gradient(90deg, ${start_color} 30%, ${stop_color} 100%);}
                    80%   { background: linear-gradient(90deg, ${start_color} 20%, ${stop_color} 100%);}
                    90%   { background: linear-gradient(90deg, ${start_color} 10%, ${stop_color} 100%);}
                    100%  { background: linear-gradient(90deg, ${start_color} 0%, ${stop_color} 100%);}
                }
                .stage .main .arrow_down path{ stroke:${start_color} }
                @supports not (-ms-high-contrast: none) {
                    .detail_conocelo_item_number_text{
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    .detail_conocelo_item_number_bg,.detail_conocelo_item_number_text{
                        background-image: linear-gradient(${start_color},${stop_color});

                    }
                    @-moz-document url-prefix() {
                        .detail_conocelo_item_number_text {
                            -webkit-text-fill-color: ${start_color};
                        }
                      }

                }
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

                    .detail_conocelo_item_number_bg{
                        background-color: ${start_color};

                    }
                    .detail_conocelo_item_number_text{
                        color: ${start_color};
                    }
                }

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    .detail_dimensions_select_container .products__change p{background: transparent; color: ${start_color};};
                }

                `,
        }}
      />
      {device !== DEVICES.desktop ? (
        <ul
          className={`modelDetail__header--categories ${
            familyModels.length <= 2 ? "content-center" : ""
          }`}
        >
          {familyModels.map(modelFamily => (
            <li>
              <Link
                to={`/calentadores/${modelFamily.uid}`}
                className={`header_nav_bottom_item ${
                  modelFamily.uid === model.uid ? "active-link" : ""
                }`}
                key={modelFamily.uid}
              >
                {modelFamily.data.svg_icon ? (
                  <div
                    className={"header-svg__container"}
                    dangerouslySetInnerHTML={{
                      __html: modelFamily.data.svg_icon,
                    }}
                  />
                ) : null}
                <p>{modelFamily.data.model_name.text}</p>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}

      <ModelHeader _model={model}></ModelHeader>
      <ModelVideo _model={model}></ModelVideo>
      <ModelMenu _model={model}></ModelMenu>
      <ModelAdvantages _model={model}></ModelAdvantages>
      <ModelYoutube _model={model}></ModelYoutube>
      <ModelLeed _model={model}></ModelLeed>
      <ModelGreenFoam _model={model} />
      <ModelNOMBadge _model={model} />
      {model.data.calorex_design ? (
        <ModelCalorexDesign _model={model}></ModelCalorexDesign>
      ) : null}
      <ModelApp _model={model}></ModelApp>
      <ModelSystems _model={model}></ModelSystems>
      <ModelDimensions _versions={versions} _item={model}></ModelDimensions>
      <ModelConocelo _model={model}></ModelConocelo>
      <ModelMaintenance _model={model}></ModelMaintenance>
      <div className="group-gradient">
        <HomeProducts></HomeProducts>
      </div>
    </Layout>
  )
}

export default CalentadoresDetail
