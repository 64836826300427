import React from 'react'

const Dealers = props => (
  <svg viewBox="0 0 82 102" {...props}>
    <path d="M41 3c21 0 38 16.9 38 37.7 0 2.4-.2 4.8-.7 7.2l-.3 1.2c-.6 2.6-1.4 5.1-2.5 7.4v.2c-4.2 10-13.6 25.4-34.1 42.3H41c-.1 0-.2 0-.4-.1C20.1 82 10.8 66.5 6.5 56.6c-1.1-2.4-2-5-2.6-7.5-.2-.6-.2-1-.2-1v-.2c-.7-3.7-.9-7.3-.5-10.9C5 18.3 20.7 3.7 39.6 3H41m0 60.9c12.6 0 22.9-10.2 22.9-22.8 0-12.6-10.3-22.8-22.9-22.8S18.1 28.6 18.1 41.2c0 12.5 10.3 22.7 22.9 22.7M41 0h-1.5C19.1.7 2.2 16.5.2 36.7c-.4 4-.2 7.9.5 11.7 0 0 .1.4.3 1.3.6 2.8 1.6 5.5 2.8 8.1 4.1 9.7 13.6 25.8 34.9 43.4.7.5 1.5.8 2.3.8.8 0 1.6-.3 2.3-.8 21.3-17.6 30.8-33.7 35-43.4 1.2-2.6 2.1-5.2 2.8-8.1.2-.8.3-1.3.3-1.3.5-2.5.7-5.1.7-7.7C82 18.2 63.7 0 41 0zm0 60.9c-11 0-19.9-8.8-19.9-19.8 0-10.9 8.9-19.8 19.9-19.8s19.9 8.8 19.9 19.8S52 60.9 41 60.9z" />
    {props.section!==undefined ? 
              
      <>                       
      <linearGradient id={`${props.section}_dealers`}>
        <stop offset="2%" stopColor={props.fillstart} />
        <stop offset="100%" stopColor={props.fillend} />
      </linearGradient>
      <linearGradient id={`${props.section}_dealers_animation`}>
        <stop offset="0%" stopColor={props.fillend} >            
        </stop>
        <stop offset="1%" stopColor={props.fillstart} >
          <animate attributeName="offset"   values=".01; .01; .01; .25; .5;  .75; .85;.98; .85;.75;.5;.25;.01;.01;0.1;" repeatCount="indefinite" dur="2s" />
        </stop>
        <stop offset="2%" stopColor={props.fillstart} >
            <animate attributeName="offset" values=".02; .25; .5;   .75; .99; .99; .99;.99;.99;.99;.99;.75;.5;0.25;0.2" repeatCount="indefinite" dur="2s" />
        </stop>
        <stop offset="100%" stopColor={props.fillend} >          
        </stop>
      </linearGradient>
      </>
      
      : null 
    }
  </svg>
)

export default Dealers