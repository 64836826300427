import React from 'react'

import  { AccessSection } from '../../../components/_access/_access_section';

function ModelMaintenance(props){
    let _model = props._model;
    
    return (
        <div className="detail_maintenance model_detail_section">
            <div className="model_maintenance_copy product__bg__gradient--h">
                <div className="model_maintenance_copy_title">Los calentadores Calorex se</div>
                <div className="model_maintenance_copy_description">convierten en parte de tu familia, aquí te ayudamos a cuidarlo como se merece.</div>

            </div>
            <AccessSection _model={_model}></AccessSection>
        </div>

    )
}
export default ModelMaintenance