import React from 'react'

const Manual = props => (
  <svg
    id="prefix__Capa_2_1_"
    x={0}
    y={0}
    viewBox="0 0 31.9 36"
    xmlSpace="preserve"
    {...props}
  >    
    <path      
      d="M9.3 4.6C7.4 4.6 5.8 6 5.7 7.8v19.9c0 2.1 1.7 3.8 3.8 3.8h16.8V4.6h-17zm0 1.5h15.4v1.6H11.2c-.2 0-.4.2-.4.4s.2.4.4.4h13.5V10H9.1c-.5 0-1-.2-1.4-.6-.3-.4-.5-.9-.5-1.5.1-1 1-1.8 2.1-1.8zM9.4 30c-1.2 0-2.2-1-2.2-2.2V10.9c.6.4 1.2.6 1.9.6h15.6V30H9.4z"
    />
    <path      
      d="M15.9 23.9c-.4 0-.7.3-.7.7 0 .4.3.7.7.7.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7zm.1-9.5c-1.8 0-3.2 1.5-3.2 3.2 0 .4.3.7.7.7.4 0 .7-.3.7-.7 0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9 0 .8-.5 1.4-1.1 1.7-.9.4-1.4 1.2-1.4 2.2 0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.1c0-.4.2-.8.6-.9 1.2-.5 2-1.7 2-3-.3-1.6-1.8-3.1-3.5-3.1z"
    />
    {props.section!==undefined ? 
      <>                       
      <linearGradient id={`${props.section}_manual`}>
        <stop offset="2%" stopColor={props.fillstart} />
        <stop offset="100%" stopColor={props.fillend} />
      </linearGradient>
      <linearGradient id={`${props.section}_manual_animation`}>
        <stop offset="0%" stopColor={props.fillend} >            
        </stop>
        <stop offset="1%" stopColor={props.fillstart} >
          <animate attributeName="offset"   values=".01; .01; .01; .25; .5;  .75; .85;.98; .85;.75;.5;.25;.01;.01;0.1;" repeatCount="indefinite" dur="2s" />
        </stop>
        <stop offset="2%" stopColor={props.fillstart} >
            <animate attributeName="offset" values=".02; .25; .5;   .75; .99; .99; .99;.99;.99;.99;.99;.75;.5;0.25;0.2" repeatCount="indefinite" dur="2s" />
        </stop>
        <stop offset="100%" stopColor={props.fillend} >          
        </stop>
      </linearGradient>
    </>
      : null 
    }
  </svg>
)

export default Manual